import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAzureSSOAuthModel } from '@client-models/auth.model';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { loginDbModel } from '@db-models/login-db.model';
import { PartnerDbModel } from '@db-models/partner-db.model';
import { WorkerDbModel } from '@db-models/worker-db.model';
import { PartnerService } from '@feature-services/partner.service';
import { PermissionService } from '@feature-services/permission.service';
import { ReleaseNotesService } from '@feature-services/release-notes.service';
import { StartSiteService } from '@feature-services/start-site.service';
import { SubscriptionService } from '@feature-services/subscription.service';
import { WorkerService } from '@feature-services/worker.service';
import { AlertToastrService } from '@util-services/alert-toastr.service';
import { CryptoService } from '@util-services/crypto.service';
import { DialogService } from '@util-services/dialog.service';
import { HttpClientService } from '@util-services/http-client.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';
import { Buffer } from 'buffer';
import * as moment from 'moment';
import { Observable, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userIsLoggedInEvent = new EventEmitter<WorkerDbModel>();
  userLogOutEvent = new EventEmitter<void>();
  workerAvatarEvent = new EventEmitter<string>();
  resetLoginFormEvent = new EventEmitter<boolean>();
  resetAuthMessageEvent = new EventEmitter<boolean>();
  #currentDate = new Date();
  queryparams: {
    [name: string]: string;
  };

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private cryptoService: CryptoService,
    private httpClientService: HttpClientService,
    private releaseNotesService: ReleaseNotesService,
    private alertToastrService: AlertToastrService,
    private workerService: WorkerService,
    private startSiteService: StartSiteService,
    private partnerService: PartnerService,
    private subscriptionService: SubscriptionService,
    private dialogService: DialogService,
    private permissionService: PermissionService
  ) {
    this.queryparams = window.location.search.substring(1).split('&').reduce(function (q, query) {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = chunks[1];
      return (q[key] = value, q);
    }, {});

    this.cryptoService.logoutEvent.subscribe({
      next: (redirectionLink: string) => this.logout({ redirect: redirectionLink, 'login-required': true })
    });
  }

  hasAuthToken(): boolean {
    const authToken = this.getAuthTokenFromLocalStorage();
    if (authToken && this.validateAuthToken(authToken)) {
      return true;
    }
    return false;
  }

  validateAuthToken(authToken: string): boolean {
    try {
      const timeObject = JSON.parse(Buffer.from(authToken?.split('.')[1], 'base64')?.toString('utf-8'));
      const currentTime = moment().unix();
      if (timeObject?.exp <= currentTime || !timeObject) { // if authToken expired
        throw Error();
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  logout(queryParams = {}): void {
    this.localStorageService.clear();
    if (Object.keys(queryParams).length) {
      this.router.navigate(['/auth', 'login'], { queryParams: queryParams });
    } else {
      this.router.navigateByUrl(`/auth/login`);
    }

    // To remove Reseller banner in app component.
    this.userLogOutEvent.emit();
  }

  login(): void {
    this.router.navigateByUrl('/auth/login');
  }

  loginUser(body: { email: string, password: string }): Observable<loginDbModel> {
    return this.httpClientService.authServicePost('auth/signin', body, {});
  }

  forgotPassword(email: string): Observable<boolean> {
    const body = { email: email }
    return this.httpClientService.authServicePost('auth/forgot-password', body, {});
  }

  resetPassword(token: string, password: string): Observable<boolean> {
    const body = { token: token, password: password }
    return this.httpClientService.authServicePost('auth/reset-password', body, {});
  }

  validateResetPasswordToken(token: string): Observable<{ success: boolean }> {
    return this.httpClientService.authServiceGet(`auth/verify-reset-password-token/`, { params: { token: token } });
  }

  setAuthCallback(loginUser: loginDbModel, isRedirectionUrl: string, loginWith: string): void {
    this.handleAuthCallback(loginUser.permissions, loginUser.worker_uuid, !!+loginUser?.is_mfa_enabled, isRedirectionUrl, loginWith);
  }

  private handleAuthCallback(permissions: string[], workerUuid: string, is_mfa_enabled = false, isRedirectionUrl = null, loginWith: string) {
    this.resetLocalStorage();

    if (is_mfa_enabled) {
      if (isRedirectionUrl && !isRedirectionUrl?.includes('mfa')) {
        this.router.navigate(['/auth', 'mfa'], { queryParams: { 'redirect': isRedirectionUrl } });
      } else {
        this.router.navigateByUrl('auth/mfa');
      }
    } else {
      if (permissions?.length) {
        this.permissionService.setUserPermissions(permissions);
      } else {
        this.alertToastrService.showError('general.user_roles_not_defined');
        setTimeout(() => this.logout(), 2000);
      }

      combineLatest([
        this.partnerService.initializeDashboard(),
        this.workerService.setLoggedInUserInLocalStorage(workerUuid),
        this.startSiteService.getStartSiteStatsByYear(this.#currentDate.getFullYear()),
        this.partnerService.validateConfig(),
        this.subscriptionService.getFeaturesListAndStoreInLocalStorage(),
      ]).subscribe({
        next: ([partnerData, worker, statisticsData]) => {
          const partnerFormLocalStorage = partnerData.partner;
          const totalBookings = statisticsData.total_bookings_ever.appointments + statisticsData.total_bookings_ever.events;
          worker?.avatar && this.localStorageService.set(LOCAL_STORAGE_CONSTANT.AVATAR_KEY, worker.avatar);
          this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CURRENT_NUMBER_OF_BOOKINGS_KEY, this.cryptoService.encryptValue(totalBookings.toString()));

          if (isRedirectionUrl) {
            this.router.navigateByUrl(isRedirectionUrl);
          } else if (
            partnerFormLocalStorage &&
            partnerFormLocalStorage?.is_setup_done === 0
          ) {
            this.router.navigate(['onboard'], {
              queryParams: {
                step: partnerFormLocalStorage.current_setup_step
              }
            }).then(() => {
              if (partnerFormLocalStorage.current_setup_step > 1) {
                this.alertToastrService.showSuccess('onboard.resume_process_message');
              }
            });
          } else {
            this.router.navigateByUrl('/').then((value: boolean) => {
              this.openPartnerReleaseNotesDialog(partnerFormLocalStorage);
            });
          }

          this.userIsLoggedInEvent.emit(worker);
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.error(error);
          this.localStorageService.clear();
          this.router.navigate(['/auth', loginWith], { queryParams: { 'something-went-wrong': true } });
          this.resetLoginFormEvent.emit();
        }
      });
    }
  }

  openPartnerReleaseNotesDialog(partner: PartnerDbModel): void {
    if (this.permissionService.hasPermission('read:partners-release-notes') && partner?.has_release_notes) {
      this.releaseNotesService.getPartnerReleaseNotes().subscribe({
        next: releaseNotes => {
          !releaseNotes && (releaseNotes = []);

          if (releaseNotes?.length) {
            this.dialogService.openPartnerReleaseNotesDialog({ releaseNotes },
              'release_notes_comp.release_notes_card_title',
              'release_notes_comp.partner_release_notes_dialog_subtitle'
            ).afterClosed().subscribe(() => this.deletePartnerReleaseNote());
          }
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
    }
  }

  deletePartnerReleaseNote(): void {
    this.releaseNotesService.deletePartnerReleaseNote().subscribe({
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  resetLocalStorage(): void {
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_APPOINTMENT_SERVICE_LIST_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SCHEDULER_ABSENCES_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SCHEDULER_AVAILABILITES_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.WORKERS_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS);
  }

  setAuthTokenInLocalStorage(authToken: string): void {
    const encryptedString = this.cryptoService.encryptValue(authToken);
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CALENSO_AUTH_TOKEN, encryptedString);
  }

  setMfaTokenInLocalStorage(mfaToken: string): void {
    const encryptedString = this.cryptoService.encryptValue(mfaToken);
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CALESNO_MFA_TOKEN, encryptedString);
  }

  getMfaTokenFromLocalStorage(): string {
    const encrypted: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CALESNO_MFA_TOKEN);
    return encrypted ? this.cryptoService.decryptValue(encrypted) : null;
  }

  getAuthTokenFromLocalStorage(): string {
    const encrypted: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CALENSO_AUTH_TOKEN);
    return encrypted ? this.cryptoService.decryptValue(encrypted) : null;
  }

  handleAzureSsoLogin(payload: IAzureSSOAuthModel): Observable<loginDbModel> {
    return this.httpClientService.authServicePost('auth/signin', payload, {});
  }

  resendVerifyEmail(partnerUuid: string): Observable<{ success: boolean }> {
    return this.httpClientService.authServicePost(`partners/send-verification-email/${partnerUuid}`, {}, {});
  }

  verifyPartnerToken(body: { token: string }): Observable<{ success: boolean }> {
    return this.httpClientService.authServicePost(`partners/verify`, body, {});
  }

  serverLogout(): Observable<{ success: boolean }> {
    return this.httpClientService.authServiceGet('auth/signout', {});
  }
}
