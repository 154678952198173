import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Params, UrlSegment } from '@angular/router';
import { APP_CONSTANTS } from '@constants/app.constants';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { PartnerDbModel } from '@db-models/partner-db.model';
import { WidgetConfModel } from '@db-models/widget-conf.model';
import { WorkerDbModel } from '@db-models/worker-db.model';
import { TranslateService } from '@ngx-translate/core';
import { CryptoService } from '@util-services/crypto.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  currentUrl: string;
  readonly appConstants = APP_CONSTANTS;
  readonly localStorageConstant = LOCAL_STORAGE_CONSTANT;

  private translate = inject(TranslateService);
  private rendererFactory2 = inject(RendererFactory2);
  private localstorageService = inject(LocalStorageService);
  private cryptoService = inject(CryptoService);
  private iconRegistry = inject(MatIconRegistry);
  private sanitizer = inject(DomSanitizer);

  protected renderer: Renderer2;

  constructor() {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  sort(collection: Array<any>): Array<any> {
    if (collection) {
      collection.sort((a: any, b: any) => {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });
      return collection;
    } else {
      return [];
    }
  }

  sortByNumber(collection: Array<any>, property: string): Array<any> {
    if (collection) {
      collection.sort((a: any, b: any) => {
        if (a[property] < b[property]) {
          return -1;
        } else if (a[property] > b[property]) {
          return 1;
        } else {
          return 0;
        }
      });
      return collection;
    } else {
      return [];
    }
  }

  sortBy(collection: Array<any>, property: string): Array<any> {
    if (collection) {
      collection.sort((a: any, b: any) => {
        if ((a[property] ? a[property].toLowerCase() : undefined) < (b[property] ? b[property].toLowerCase() : undefined)) {
          return -1;
        } else if (a[property] > b[property]) {
          return 1;
        } else {
          return 0;
        }
      });
      return collection;
    } else {
      return [];
    }
  }

  /**
   * To sort an array by specified field.
   * Usage: [].sort(sortArrayObjectsByField('sort_by_field_name))
   * @param property Sort field
   * @author Pratik Padia
   */
  sortArrayObjectsByField(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substring(1);
    }
    return (a: any, b: any) => {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  /**
  * // To sort array by specified field.
  * Usage: [].sort(nestedSort("sort_by_field_name"));
  * Usage: [].sort(nestedSort("sort_by_field_name", null, "desc"));
  * // To sort nested array by specified field.
  * Usage: [].sort(nestedSort("sort_by_field_name", "nested_field_name"));
  * Usage: [].sort(nestedSort("sort_by_field_name", "nested_field_name", "desc"));
  * @param prop1 Sort field
  * @param prop2 nested sort field
  * @param direction desc/asc
  * @author Kathan Patel
  */
  nestedSort = (prop1, prop2 = null, direction = 'asc'): any => (e1, e2): any => {
    const a = prop2 ? e1[prop1][prop2] : e1[prop1]
    const b = prop2 ? e2[prop1][prop2] : e2[prop1]
    const sortOrder = direction === "asc" ? 1 : -1
    return (a < b) ? -sortOrder : (a > b) ? sortOrder : 0;
  }

  // Rounding the minute to 0, 5, 10, 15, 20
  public createRoundDate(d: Date, hours?: number): Date | string {
    const date: moment.Moment = moment(d);
    if (hours) {
      date.set({ hour: hours, minute: 0 });
    }
    const minutes: number = date.minutes();
    const mod: number = minutes % 10;
    let finalMinutes: number = minutes;
    if (mod < 5) {
      finalMinutes = minutes - mod;
    } else if (mod > 5) {
      finalMinutes = minutes - (mod - 5);
    }
    date.set('minutes', finalMinutes);
    return date.toDate();
  }

  dateAgoUtility(value: string): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      } // less than 30 seconds ago will show as 'Just now'
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };

      let counter;

      for (const i in intervals) {
        if (intervals[i]) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0) {
            if (counter === 1) {
              let translation: string = this.translate.instant(`notification_component.x_${i + '_ago'}`);
              translation = translation.replace('x ', counter + ' ');
              return translation; // singular (1 day ago)
            } else {
              let translation: string = this.translate.instant(`notification_component.x_${i + 's_ago'}`);
              translation = translation.replace('x ', counter + ' ');
              return translation; // plural (2 days ago)
            }
          }
        }
      }
    }
    return value;
  }

  public createListOfYearsOnAbsenseForm(currentYear: number): number[] {
    const listOfYears: number[] = [];
    for (const i of [0, 1, 2]) {
      listOfYears.push(currentYear + i);
    }
    return listOfYears;
  }

  public createListOfYears(currentYear: number): number[] {
    const listOfYears: number[] = [];
    const minYear = 2019;
    listOfYears.push(currentYear + 1);
    for (let index = 0; index <= (currentYear - minYear); index++) {
      listOfYears.push(currentYear - index);
    }
    return listOfYears;
  }

  isNotEmptyString(value: string): string {
    if (Array.isArray(value)) {
      return undefined;
    }

    if (!value || value?.trim() === '' || value === 'null' || value === null || value === undefined) {
      return undefined;
    }
    return value;
  }

  isEmptyString(value: string): string {
    if (value) {
      if (value.trim() === '') {
        return null;
      } else {
        return value;
      }
    } else {
      return null;
    }
  }

  public replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  public getDefaultLanguage(
    localStorageLang: string,
    partnerData: PartnerDbModel,
    loggedInWorker: WorkerDbModel,
  ): string {
    let language: string;
    const userLang = navigator.language;

    if (localStorageLang) {
      language = localStorageLang;
    } else if (loggedInWorker && loggedInWorker.lang_identifier) {
      language = loggedInWorker.lang_identifier;
    } else {
      this.appConstants.LANGUAGES.forEach(languages => {
        if (userLang.includes(languages.value)) {
          language = languages.locale;
        }
      });
    }

    if (language === undefined) {
      if (partnerData && partnerData.language_identifier) {
        language = partnerData.language_identifier;
      } else {
        language = 'de_CH';
      }
    }

    return language;
  }

  getBookingPageLang(partnerData: PartnerDbModel): string {
    const userLang: string = navigator.language;
    let browserLang: string;
    const partnerWidgetLangs: string[] = [];
    if (partnerData && partnerData.supported_widget_languages && partnerData.supported_widget_languages.length > 0) {
      this.appConstants.LANGUAGES.forEach(languages => {
        if (partnerData.supported_widget_languages.includes(languages.value)) {
          partnerWidgetLangs.push(languages.locale);
        }
      });
    }

    const foundBrowserLang = this.appConstants.LANGUAGES.find(languages => userLang.includes(languages.value));
    if (foundBrowserLang) {
      browserLang = foundBrowserLang.locale;
    } else {
      browserLang = 'de_CH';
    }

    if (partnerWidgetLangs.indexOf(browserLang) > -1) {
      return browserLang;
    } else {
      if (partnerWidgetLangs.length > 0) {
        return partnerWidgetLangs[0];
      } else {
        return browserLang;
      }
    }
  }

  getSurveyPageLang(widgetConf: WidgetConfModel): string {
    const userLang: string = navigator.language;
    let browserLang: string;
    const supportedLangs: string[] = [];
    if (widgetConf && widgetConf.supported_widget_languages && widgetConf.supported_widget_languages.length > 0) {
      this.appConstants.LANGUAGES.forEach(languages => {
        if (widgetConf.supported_widget_languages.includes(languages.value)) {
          supportedLangs.push(languages.locale);
        }
      });
    }

    const foundBrowserLang = this.appConstants.LANGUAGES.find(languages => userLang.includes(languages.value));
    if (foundBrowserLang) {
      browserLang = foundBrowserLang.locale;
    } else {
      browserLang = widgetConf.locale;
    }

    if (supportedLangs.indexOf(browserLang) > -1) {
      return browserLang;
    } else {
      if (supportedLangs.length > 0) {
        return supportedLangs[0];
      } else {
        return browserLang;
      }
    }
  }

  public formatDate(date: string) {
    if (!date) {
      return null;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  isIEorEdgeBrowser(): boolean {
    if (window) {
      const isIE = /edge|msie\s|trident\//i.test(window.navigator.userAgent);
      return isIE;
    }
    return false;
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  removeDuplicates(arr: any[], prop: string): any[] {
    // Create an object to store unique keys
    const obj = {};

    // Filter the array to remove duplicates
    return Object.keys(arr.reduce((prev, next) => {
      if (!obj[next[prop]]) {
        obj[next[prop]] = next;
      }
      return obj;
    }, obj)).map((i) => obj[i]);
  }

  /**
   * Groups an array of objects with multiple properties.
   *
   * @param  {Array}  array: the array of objects to group
   * @param  {Array} props: the properties to groupby
   * @return {Array} an array of arrays with the grouped results
   */
  public groupBy(collection: any[], props: string[]) {
    const getGroupedItems = (item) => {
      const returnArray = [];
      let i;
      for (i = 0; i < props.length; i++) {
        returnArray.push(item[props[i]]);
      }
      return returnArray;
    };

    const groups = {};
    let i;

    for (i = 0; i < collection.length; i++) {
      const arrayRecord = collection[i];
      const group = JSON.stringify(getGroupedItems(arrayRecord));
      groups[group] = groups[group] || [];
      groups[group].push(arrayRecord);
    }
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  }

  getBrowserName(): string {
    if ((navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) // IF IE > 10
    {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  validateEmail(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  public findStringBetween(str: string, char1: string, char2: string): string {
    return str.split(char1).pop().split(char2)[0];
  }

  scrollToElementById(
    id: string,
    enableHighlighting: boolean,
  ) {
    const element = document.getElementById(id);
    LoggerService.log('Inside scrollToId element ', element);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });

      if (enableHighlighting) {
        if (element.classList) {
          element.classList.add('calio-global-search-highlighter');
        }
      }
    }
  }

  getAllQueryParams(): Params {
    return window.location.search.substring(1).split('&').reduce((q, query) => {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = chunks[1];
      return (q[key] = value, q);
    }, {});
  }

  downloadBase64Image(base64String: string): void {
    const downloadLink = document.createElement('a');
    const fileName = 'Calenso.png';
    downloadLink.href = base64String;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  setRedirectUrl(url: string): void {
    const encryptedUrl = this.cryptoService.encryptValue(url);
    this.localstorageService.set(this.localStorageConstant.REDIRECT_TO, encryptedUrl);
  }

  getRedirectUrl(clear = false): string {
    const encryptedUrl = this.localstorageService.get(this.localStorageConstant.REDIRECT_TO);
    let decryptedUrl = null;

    if (encryptedUrl) {
      decryptedUrl = this.cryptoService.decryptValue(encryptedUrl);
    }

    if (clear) {
      this.localstorageService.remove(this.localStorageConstant.REDIRECT_TO);
    }

    return decryptedUrl;
  }

  /**
   * Create url from Segments
   * @param segments Object in which need to create url
   * @returns returns the url
   */
  getUrlPathFromSegments(segments: UrlSegment[]): string {
    let urlPath = '';
    segments?.length && segments?.forEach((segment) => urlPath += segment?.path ? `${segment.path}/` : '');
    return urlPath.endsWith('/') ? urlPath.slice(0, -1) : urlPath;
  }

  /**
   * Search specific key value from object
   * @param obj Object in which need to perform an search
   * @param key Key name which needs to find from search object
   * @returns returns the value of the key if found else undefined
   */
  findKeyValueFromObject(obj: any, key: string): any | undefined {
    // Check if the current object has the key
    if (obj?.hasOwnProperty(key)) {
      return obj[key];
    }

    // Iterate over the object's properties
    for (let prop in obj) {
      if (typeof obj[prop] === 'object') {
        // Recursively call findKeyValueFromObject for nested objects
        let nestedValue = this.findKeyValueFromObject(obj[prop], key);

        // If the nested object contains the key, return its value
        if (nestedValue !== undefined) {
          return nestedValue;
        }
      }
    }

    // Key not found in the object
    return undefined;
  }

  /**
   * Search for a value in an array of objects (including nested objects)
   * @param array array in which need to perform an search
   * @param searchValue search string
   * @returns returns the array
   */
  searchObjectArray(array, searchValue) {
    function searchNestedObject(obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === 'object') {
            // If the property is an object, recursively search it
            const result = searchNestedObject(obj[key]);
            if (result !== null) {
              return result;
            }
          } else if (obj[key].toString().includes(searchValue)) {
            // If the value contains the search string, return the entire object
            return obj;
          }
        }
      }
      // If no match is found, return null
      return null;
    }

    // Array to store matching objects
    const matchingObjects = [];

    // Iterate through each object in the array
    for (let i = 0; i < array.length; i++) {
      const result = searchNestedObject(array[i]);
      if (result !== null) {
        matchingObjects.push(array[i]);
      }
    }

    // Return the array of matching objects
    return matchingObjects.length > 0 ? matchingObjects : null;
  }

  private registerSvgIcon(iconName: string, assetPath: string): void {
    this.iconRegistry.addSvgIcon(
      iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(assetPath)
    );
  }

  registerSvgIconsList(svgIcons: string[]): void {
    svgIcons.forEach(icon => this.registerSvgIcon(`calio-${icon}-icon`, `assets/images/icons/${icon}.svg`));
  }

  scrollToTop(): void {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  needToNavigateOnDashboard(route: string): boolean {
    return route.startsWith('/app/settings') || route.startsWith('/app/integrations');
  }

  isValidMimeTypes(file: any, mimeTypes: string[]): boolean {
    const target = file.target as DataTransfer;
    const mimeType = target.files[0].type;
    return mimeTypes.includes(mimeType);
  }

  // Helper for get unique workers that supports common services
  getCommonWorkers(workers: WorkerDbModel[], numberOfServies: number): WorkerDbModel[] {
    const duplicates: WorkerDbModel[] = [];
    const addedKeys: Map<number, number> = new Map<number, number>();

    for (const worker of workers) {
      const keyValue = worker.id;
      if (addedKeys.has(keyValue)) {
        addedKeys.set(keyValue, Number(addedKeys.get(keyValue)) + 1)
      } else {
        addedKeys.set(keyValue, 1);
      }

      if (addedKeys.get(keyValue) === numberOfServies) {
        duplicates.push(worker);
      }
    }

    return duplicates;
  }
}
