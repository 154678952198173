import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private envVersion =  environment.version.split('.').slice(0, 2).join('.');

  get(key: string): string {
    return window.sessionStorage.getItem(key + '_' + this.envVersion);
  }

  set(key: string, value: string): void {
    window.sessionStorage.setItem(key + '_' + this.envVersion, value);
  }

  remove(key: string): void {
    window.sessionStorage.removeItem(key + '_' + this.envVersion);
  }

  clear(): void {
    window.sessionStorage.clear();
  }
}
