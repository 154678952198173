export const LOCAL_STORAGE_CONSTANT = {
  LANGUAGE_KEY: 'lk',
  COUNTRIES_KEY: 'ck',
  APPOINTMENT_SERVICE_KEY: 'ask',
  APPOINTMENT_KEY: 'apk',
  STORES_KEY: 'sk',
  WORKERS_KEY: 'wk',
  PARTNER_KEY: 'pk',
  FEATURE_KEY: 'fk',
  EVENT_KEY: 'ek',
  HIDE_UPGRADE_NOW_BUTTON_KEY: 'hunbk',
  CUSTOMER_TYPES: 'ctk',
  AVATAR_KEY: 'avk',
  SHORT_WORKER_LIST_KEY: 'swlk',
  SHORT_CUSTOMER_LIST_KEY: 'sclk',
  SHORT_CATEGORIES_LIST_KEY: 'scatlk',
  SHORT_APPOINTMENT_SERVICE_LIST_KEY: 'saslk',
  CURRENT_LANGUAGE_STATE_KEY: 'clsk',
  CURRENT_NUMBER_OF_BOOKINGS_KEY: 'nobk',
  SCHEDULER_ABSENCES_KEY: 'cd_sabk',
  SCHEDULER_AVAILABILITES_KEY: 'cd_savk',
  CUSTOMER_LIST_VIEW_SELECTION: 'cd_clvs',
  CUSTOMER_LIST_SORT_ORDER: 'cd_so',
  BOOKINGS_SELECTED_WORKERS: 'cd_bsw',
  BOOKINGS_SELECTED_MODE_OPTION: 'cd_bsmd',
  LOGGED_IN_WORKER: 'cd_liw',
  ANONYMOUS_USER_CREDENTIALS: 'cd_auc',
  SUBSCRIPTION_DETAIL: 'cd_sd',
  USER_PERMISSIONS: 'cd_up',
  CUSTOMER_FORM_DATA: 'cd_cfd',
  CUSTOMER_CUSTOM_FIELD_FORM_DATA: 'cd_ccffd',
  CUSTOMER_CUSTOM_FIELD_FILES_FORM_DATA: 'cd_ccfffd',
  SELECTED_WORKERS: 'cd_sw',
  CALENDAR_PARTNER_SETTINGS: 'cd_cps',
  CONFIG_VALIDATION: 'cd_cv',
  APPOINTMENT_SORT_ORDER: 'appt_so',
  MFA: 'cd_slsv',
  REDIRECT_TO: 'cd_rdto',
  LAST_GLOBAL_NOTIFICATION_FETCH_TIME: 'lt_gnft',
  SUBSCRIPTION_FEATURES_LIST: 'sft',
  CALENSO_AUTH_TOKEN: 'cd_cat',
  CALESNO_MFA_TOKEN: 'cd_cmat',
  CALENSO_PUBLIC_ROUTES_AUTH_TOKEN: 'cd_cpar',
  CALENSO_SELECTED_SSO_CLIENT_ID: 'cd_csscid',
  CALENSO_SSO_AUTO_LOGIN: 'cd_cssal',
  CALENSO_PARTNER_COUNTRY: 'cd_cpc',
  PARTNER_PREVIOUS_SEARCH_LIST: 'cd_ppsl',
  FAVORITE_PARTNERS: 'cd_fp',
  START_SITE_DATA: 'cd_std',
  CURRENT_THEME: 'cd_ct',
  CALENDLY_FORM_DATA: 'cd_cfd',
};

export const SESSION_STORAGE_CONSTANT = {
  CALENDAR_START_DATE: 'cd_csd',
  CALENDAR_END_DATE: 'cd_ced',
  CALENDAR_SELECTED_MODE: 'cd_csm',
  CALENDAR_SELECTED_STORES: 'cd_css',
  CALENDAR_SELECTED_WORKERS: 'cd_csw',
  LANDING_PAGE_AUTH_TOKEN: 'cd_lpa',
};
