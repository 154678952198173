import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { environment } from '@environments/environment';
import { LoggerService } from '@util-services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private envVersion =  environment.version.split('.').slice(0, 2).join('.');
  private readonly withoutVersionKeysList = [
    LOCAL_STORAGE_CONSTANT.CALENSO_AUTH_TOKEN,
    LOCAL_STORAGE_CONSTANT.CALESNO_MFA_TOKEN,
    LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY,
    LOCAL_STORAGE_CONSTANT.MFA,
    LOCAL_STORAGE_CONSTANT.LAST_GLOBAL_NOTIFICATION_FETCH_TIME,
    LOCAL_STORAGE_CONSTANT.CALENSO_SELECTED_SSO_CLIENT_ID,
    LOCAL_STORAGE_CONSTANT.CALENSO_SSO_AUTO_LOGIN,
    LOCAL_STORAGE_CONSTANT.PARTNER_PREVIOUS_SEARCH_LIST,
    LOCAL_STORAGE_CONSTANT.FAVORITE_PARTNERS
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any
  ) {
  }

  get(key: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        // Added separate case for keys which doesn't requires _ and version in localstorage
        if (this.withoutVersionKeysList.includes(key) || key.startsWith(LOCAL_STORAGE_CONSTANT.CURRENT_THEME)) {
          // Fetch value for both with and without version, first preference will
          // be given to without version key value
          const woVersion = this.localStorage.getItem(key);
          const wVersion = this.localStorage.getItem(key + '_' + this.envVersion);
          return woVersion || wVersion;
        } else {
          return this.localStorage.getItem(key + '_' + this.envVersion);
        }
      }
    } catch (e) {
      LoggerService.warn('Error while fetching value from localstorage');
    }
  }

  set(key: string, value: string): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        // Added seperate case for keys which doesn't requires _ and version in localstorage
        if (this.withoutVersionKeysList.includes(key) || key.startsWith(LOCAL_STORAGE_CONSTANT.CURRENT_THEME)) {
          // will not use versioning, if present it will be removed and without version will be set
          this.localStorage.removeItem(key + '_' + this.envVersion);
          this.localStorage.setItem(key, value);
        } else {
          this.localStorage.setItem(key + '_' + this.envVersion, value);
        }
      }
    } catch (e) {
      LoggerService.warn('Error while setting value in localstorage');
    }
  }

  remove(key: string): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        this.localStorage.removeItem(key + '_' + this.envVersion);

        if (this.withoutVersionKeysList.includes(key)) {
          this.localStorage.removeItem(key);
        }
      }
    } catch (e) {
      LoggerService.warn('Error while removing value from localstorage');
    }
  }

  clear(): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const loggedInWorker = this.get(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER);
        const currentLang = this.get(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY);
        const screenType = this.get(LOCAL_STORAGE_CONSTANT.CUSTOMER_LIST_VIEW_SELECTION);
        const favoritePartners = this.get(LOCAL_STORAGE_CONSTANT.FAVORITE_PARTNERS);
        const partnerPreviousSearchList = this.get(LOCAL_STORAGE_CONSTANT.PARTNER_PREVIOUS_SEARCH_LIST);
        const currentThemeConstantKey = loggedInWorker ? `${LOCAL_STORAGE_CONSTANT.CURRENT_THEME}_${loggedInWorker?.uuid}` : '';
        const currentTheme = this.get(currentThemeConstantKey);

        this.localStorage.clear();
        window.sessionStorage.clear();

        currentLang && this.set(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY, currentLang);
        screenType && this.set(LOCAL_STORAGE_CONSTANT.CUSTOMER_LIST_VIEW_SELECTION, screenType);
        favoritePartners && this.set(LOCAL_STORAGE_CONSTANT.FAVORITE_PARTNERS, favoritePartners);
        partnerPreviousSearchList && this.set(LOCAL_STORAGE_CONSTANT.PARTNER_PREVIOUS_SEARCH_LIST, partnerPreviousSearchList);
        currentTheme && this.set(currentThemeConstantKey, currentTheme);
      }
    } catch (e) {
      LoggerService.warn('Error while clearing localstorage');
    }
  }
}
